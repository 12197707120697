import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function OrganizationCell({
    row: { organization, organizationCode, organizationName },
}: OrganizationCellProps): ReactElement {
    const history = useHistory();
    const organizationUri = `/organizations/${organization}`;

    const onOrganizationClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(organizationUri);
        },
        [history, organizationUri],
    );

    return (
        <>
            <a href={organizationUri} onClick={onOrganizationClick} className={'hover:underline'}>
                <b>{organizationCode}</b> - {organizationName}
            </a>
        </>
    );
}

export type OrganizationCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default OrganizationCell;
