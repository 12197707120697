import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function PartnerCell({ value, row: { partnerName } }: PartnerCellProps): ReactElement {
    const history = useHistory();
    const partnerUri = `/partners/${value}`;

    const onPartnerClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            history.push(partnerUri);
        },
        [history, partnerUri],
    );

    return value ? (
        <>
            <a href={partnerUri} onClick={onPartnerClick} className={'hover:underline'}>
                <b>{partnerName}</b>
            </a>
        </>
    ) : (
        <>{''}</>
    );
}

export type PartnerCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default PartnerCell;
