import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function BookSellerCell({
    value,
    row: { sellerFirstName, sellerLastName, sellerCode },
}: BookSellerCellProps): ReactElement {
    const history = useHistory();
    const sellerUri = `/sellers/${value}`;

    const onSellerClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            history.push(sellerUri);
        },
        [history, sellerUri],
    );

    return value ? (
        <a href={sellerUri} onClick={onSellerClick} className={'hover:underline'}>
            <b>{sellerCode}</b> - {sellerFirstName} {sellerLastName}
        </a>
    ) : (
        <>{''}</>
    );
}

export type BookSellerCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default BookSellerCell;
