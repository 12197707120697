import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function PlatformCell({ row: { platform, platformCode, platformName } }: PlatformCellProps): ReactElement {
    const history = useHistory();
    const platformUri = `/platforms/${platform}`;

    const onPlatformClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(platformUri);
        },
        [history, platformUri],
    );

    return (
        <a href={platformUri} onClick={onPlatformClick} className={'hover:underline'}>
            <b>{platformCode}</b> - {platformName}
        </a>
    );
}

export type PlatformCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default PlatformCell;
