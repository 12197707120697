import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function TenantCell({ value, row: { tenantName } }: TenantCellProps): ReactElement {
    const history = useHistory();
    const tenantUri = `/tenants/${value}`;

    const onTenantClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            history.push(tenantUri);
        },
        [history, tenantUri],
    );

    return value ? (
        <>
            <a href={tenantUri} onClick={onTenantClick} className={'hover:underline'}>
                <b>{tenantName}</b>
            </a>
        </>
    ) : (
        <>{''}</>
    );
}

export type TenantCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default TenantCell;
