import { ReactElement } from 'react';
import { BaseCell } from '../types';
import { useTranslation } from 'react-i18next';

export function RegistrationAgeCell({ row: { createdAt } }: RegistrationAgeCellProps): ReactElement {
    const { t } = useTranslation();
    if (!createdAt) return <></>;
    const formattedCreatedAt = new Date(createdAt);
    const ageInDays = Math.floor((Date.now() - formattedCreatedAt.getTime()) / (1000 * 60 * 60 * 24));

    switch (true) {
        case ageInDays >= 30:
            return <div className="text-gray-500">{t('registration_age', { age: ageInDays })}</div>;
        case ageInDays >= 10:
            return <div className="text-red-500">{t('registration_age', { age: ageInDays })}</div>;
        case ageInDays >= 3:
            return <div className="text-[#FFA500]">{t('registration_age', { age: ageInDays })}</div>;
        default:
            return <div className="text-black">{t('registration_age', { age: ageInDays })}</div>;
    }
}

export type RegistrationAgeCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default RegistrationAgeCell;
