import { BaseCell } from '../types';
import { formatAmount } from '@gotombola/amount';
import { ReactElement } from 'react';

export function DonationAmountCell({ value, row: { paymentCurrency } }: DonationAmountCellProps): ReactElement {
    return <>{formatAmount(value / 100, paymentCurrency)}</>;
}

export type DonationAmountCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default DonationAmountCell;
