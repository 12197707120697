import { BaseCell } from '../types';
import { formatAmount } from '@gotombola/amount';
import { ReactElement } from 'react';

export function TicketPriceAmountCell({
    value,
    row: { ticketPriceCurrency },
}: TicketPriceAmountCellProps): ReactElement {
    return <>{formatAmount(value / 100, ticketPriceCurrency)}</>;
}

export type TicketPriceAmountCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default TicketPriceAmountCell;
