import { BaseCell } from '../types';
import { ReactElement } from 'react';

function DateCell({ value }: DateCellProps): ReactElement {
    return <>{new Date(value).toLocaleString()}</>;
}

export type DateCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default DateCell;
