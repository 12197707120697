import { BaseCell } from '../types';
import { formatAmount } from '@gotombola/amount';
import { ReactElement } from 'react';

export function PaymentAmountCell({ value, row: { paymentCurrency } }: PaymentAmountCellProps): ReactElement {
    return <>{formatAmount(value / 100, paymentCurrency)}</>;
}

export type PaymentAmountCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default PaymentAmountCell;
