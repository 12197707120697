import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseCell } from '../types';

export function OwnerCell({ value, row: { ownerEmail, ownerPhone } }: OwnerCellProps): ReactElement {
    const isEmail = value?.indexOf('@') > -1;
    const history = useHistory();
    const ownerUri = `/users/${value}`;

    const onOwnerClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(ownerUri);
        },
        [history, ownerUri],
    );

    return value ? (
        isEmail ? (
            <>{value}</>
        ) : (
            <a href={ownerUri} onClick={onOwnerClick} className={'hover:underline'}>
                <b>{ownerEmail || ownerPhone}</b>
            </a>
        )
    ) : (
        <>{''}</>
    );
}

export type OwnerCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default OwnerCell;
