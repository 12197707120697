import { ReactElement } from 'react';
import { BaseCell, Registration } from '../types';
import clsx from 'clsx';

function computedAdvancementFromData(data: Registration) {
    const res = {
        O: { concept: 'O', count: 0, total: 8 },
        G: { concept: 'G', count: 0, total: 23 },
        U: { concept: 'U', count: 0, total: 8 },
        P: { concept: 'P', count: 0, total: 4 },
    };

    // ORGANIZATION
    !!data.requestedOrganizationDescription && res['O'].count++;
    !!data.requestedOrganizationDomain && res['O'].count++;
    !!data.requestedOrganizationFacebookUrl && res['O'].count++;
    !!data.requestedOrganizationName && res['O'].count++;
    !!data.requestedOrganizationOverline && res['O'].count++;
    !!data.requestedOrganizationPresentation && res['O'].count++;
    !!data.requestedOrganizationType && res['O'].count++;
    !!data.requestedOrganizationWebsiteUrl && res['O'].count++;
    // GAME
    !!data.requestedBunchList && res['G'].count++;
    !!data.requestedGameDescription && res['G'].count++;
    !!data.requestedGameName && res['G'].count++;
    !!data.requestedGameOptions && res['G'].count++;
    !!data.requestedGameOverline && res['G'].count++;
    !!data.requestedGamePresentation && res['G'].count++;
    !!data.requestedGameSubject && res['G'].count++;
    !!data.requestedGameTheme && res['G'].count++;
    !!data.requestedGimage1Image?.url && res['G'].count++;
    !!data.requestedGimage2Image?.url && res['G'].count++;
    !!data.requestedGimage3Image?.url && res['G'].count++;
    !!data.requestedGimage4Image?.url && res['G'].count++;
    !!data.requestedGimage5Image?.url && res['G'].count++;
    !!data.requestedGimage6Image?.url && res['G'].count++;
    !!data.requestedGimage7Image?.url && res['G'].count++;
    !!data.requestedGimage8Image?.url && res['G'].count++;
    !!data.requestedPlannedCustomTickets && res['G'].count++;
    !!data.requestedPlannedDrawnAt && res['G'].count++;
    !!data.requestedPlannedStartedAt && res['G'].count++;
    !!data.requestedPlannedTickets && res['G'].count++;
    !!data.requestedSaleData && res['G'].count++;
    !!data.requestedTicketPriceAmount && res['G'].count++;
    !!data.requestedTicketPriceCurrency && res['G'].count++;
    // USER
    !!data.email && res['U'].count++;
    !!data.firstName && res['U'].count++;
    !!data.lastName && res['U'].count++;
    !!data.phone && res['U'].count++;
    !!data.requestedCity && res['U'].count++;
    !!data.requestedCountry && res['U'].count++;
    !!data.requestedLocale && res['U'].count++;
    !!data.requestedZipCode && res['U'].count++;
    // PAGE
    !!data.requestedBannerImage?.url && res['P'].count++;
    !!data.requestedLogoImage?.url && res['P'].count++;
    !!data.requestedSupportedLocales && res['P'].count++;
    !!data.requestedThemeCode && res['P'].count++;

    return Object.keys(res).map((k) => {
        const x = res[k as keyof typeof res];
        const ratio = x.count / x.total;
        switch (true) {
            case 0 === ratio:
                return { ...x, className: 'bg-gray-300' };
            case 0.5 > ratio:
                return { ...x, className: 'bg-green-200' };
            case 0.8 > ratio:
                return { ...x, className: 'bg-green-500' };
            default:
                return { ...x, className: 'bg-green-800 text-white' };
        }
    });
}

// eslint-disable-next-line react/prop-types
export function RegistrationAdvancementCell({ row }: RegistrationAdvancementCellProps): ReactElement {
    const items = (computedAdvancementFromData(row) || []).map(
        ({ concept, className }: { concept: string; className: string }, index: number) => {
            return (
                <div key={index} className={clsx('flex items-center justify-center w-6 h-6 rounded-full', className)}>
                    {concept}
                </div>
            );
        },
    );

    return (
        <div className={'p-2'}>
            <div className={'flex gap-1'}>{items}</div>
        </div>
    );
}

export type RegistrationAdvancementCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default RegistrationAdvancementCell;
