import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function BunchCell({ row: { bunch, bunchCode, bunchName } }: BunchCellProps): ReactElement {
    const history = useHistory();
    const bunchUri = `/bunches/${bunch}`;

    const onBunchClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(bunchUri);
        },
        [history, bunchUri],
    );

    return (
        <>
            <a href={bunchUri} onClick={onBunchClick} className={'hover:underline'}>
                {bunchName ? (
                    <>
                        <b>{bunchName}</b> ({bunchCode})
                    </>
                ) : (
                    <b>{bunchCode}</b>
                )}
            </a>
        </>
    );
}

export type BunchCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default BunchCell;
