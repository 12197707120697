import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function OrganizationOwnerCell({ value, row: { ownerEmail } }: OrganizationOwnerCellProps): ReactElement {
    const history = useHistory();
    const ownerUri = `/users/${value}`;

    const onOwnerClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            history.push(ownerUri);
        },
        [history, ownerUri],
    );

    return value ? (
        <a href={ownerUri} onClick={onOwnerClick} className={'hover:underline'}>
            <b>{ownerEmail}</b>
        </a>
    ) : (
        <>{''}</>
    );
}

export type OrganizationOwnerCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default OrganizationOwnerCell;
