import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function SellerSellergroupCell({
    row: { sellergroupName, sellergroupCode, sellergroup },
}: SellerSellergroupCellProps): ReactElement {
    const history = useHistory();
    const sellergroupUri = `/sellergroups/${sellergroup}`;
    const onSellerSellergroupClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(sellergroupUri);
        },
        [history, sellergroupUri],
    );
    return sellergroup ? (
        <>
            <a href={sellergroupUri} onClick={onSellerSellergroupClick} className={'hover:underline'}>
                <b>{sellergroupCode}</b> - {sellergroupName}
            </a>
        </>
    ) : (
        <>{''}</>
    );
}

export type SellerSellergroupCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default SellerSellergroupCell;
