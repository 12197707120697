import { BaseCell } from '../types';
import { formatAmount } from '@gotombola/amount';
import { ReactElement } from 'react';

export function StatPaidAmountCell({ value, row: { ticketPriceCurrency } }: StatPaidAmountCellProps): ReactElement {
    return <>{formatAmount(value / 100, ticketPriceCurrency)}</>;
}

export type StatPaidAmountCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default StatPaidAmountCell;
