import { BaseCell } from '../types';
import { formatAmount } from '@gotombola/amount';
import { ReactElement } from 'react';

export function BunchPriceCell({ value, row: { currency } }: BunchPriceCellProps): ReactElement {
    return <>{formatAmount(value, currency)}</>;
}

export type BunchPriceCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default BunchPriceCell;
