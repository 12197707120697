import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function BookCell({ row: { book, bookCode, bookCustomCode } }: BookCellProps): ReactElement {
    const history = useHistory();
    const bookUri = `/books/${book}`;

    const onBookClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(bookUri);
        },
        [history, bookUri],
    );

    return (
        <>
            <a href={bookUri} onClick={onBookClick} className={'hover:underline'}>
                {bookCustomCode ? (
                    <>
                        <b>{bookCustomCode}</b> ({bookCode})
                    </>
                ) : (
                    <b>{bookCode}</b>
                )}
            </a>
        </>
    );
}

export type BookCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default BookCell;
