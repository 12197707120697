import { BaseCell } from '../types';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

export function BookPaymentStatusSelfCell({ value }: BookPaymentStatusSelfCellProps): ReactElement {
    const { t } = useTranslation();

    switch (value) {
        case 'NOT_PAID':
            return (
                <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{t('book_payment_status_not_paid')}</div>
            );
        case 'PARTIALLY_PAID':
            return (
                <div className={'rounded p-1 text-sm bg-warning text-white'}>
                    {t('book_payment_status_partially_paid')}
                </div>
            );
        case 'PAID':
            return <div className={'rounded p-1 text-sm bg-green-500 text-white'}>{t('book_payment_status_paid')}</div>;
        default:
            return (
                <div className={'rounded p-1 text-sm text-gray-500'}>{value !== null ? value.toLowerCase() : ''}</div>
            );
    }
}

export type BookPaymentStatusSelfCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default BookPaymentStatusSelfCell;
