import { useTranslation } from 'react-i18next';
import { BaseCell } from '../types';
import { ReactElement } from 'react';

export function SaleStatusCell({ value }: SaleStatusCellProps): ReactElement {
    const { t } = useTranslation();

    switch (value) {
        case 'PAID':
            return <div className={'rounded p-1 text-sm bg-green-500 text-white'}>{t('sale_status_paid')}</div>;
        case 'CREATED':
            return (
                <div className={'rounded p-1 text-sm border border-black text-black'}>{t('sale_status_created')}</div>
            );
        case 'PROMISED':
            return <div className={'rounded p-1 text-sm bg-blue-500 text-white'}>{t('sale_status_promised')}</div>;
        case 'FAILED':
            return <div className={'rounded p-1 text-sm bg-red-500 text-white'}>{t('sale_status_failed')}</div>;
        case 'CANCELED':
            return <div className={'rounded p-1 text-sm bg-warning text-white'}>{t('sale_status_canceled')}</div>;
        default:
            return <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{value.toLowerCase()}</div>;
    }
}

export type SaleStatusCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default SaleStatusCell;
