import { FlagIcon } from '@genstackio/react-admin-ui/lib/atoms/FlagIcon';
import { BaseCell } from '../types';
import { ReactElement } from 'react';

export function LocaleCell({ value }: LocaleCellProps): ReactElement {
    return value ? <FlagIcon locale={value.replace('_', '-')} /> : <></>;
}

export type LocaleCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default LocaleCell;
