import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function GameCell({
    row: { game, gameCode, gameName, organization, organizationName },
}: GameCellProps): ReactElement {
    const history = useHistory();
    const gameUri = `/games/${game}`;
    const organizationUri = `/organizations/${organization}`;

    const onGameClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(gameUri);
        },
        [history, gameUri],
    );

    const onOrganizationClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(organizationUri);
        },
        [history, organizationUri],
    );

    if (!game && !organization) return <>{''}</>;

    return (
        <>
            {!!game ? (
                <a href={gameUri} onClick={onGameClick} className={'hover:underline'}>
                    <b>{gameCode}</b> - {gameName}
                </a>
            ) : (
                <>{''}</>
            )}
            &nbsp; (
            <a href={organizationUri} onClick={onOrganizationClick} className={'hover:underline'}>
                {organizationName}
            </a>
            )
        </>
    );
}

export type GameCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default GameCell;
