import { BaseCell } from '../types';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

export function NotificationStatusCell({ value }: NotificationStatusCellProps): ReactElement {
    const { t } = useTranslation();

    switch (value) {
        case 'CREATED':
            return (
                <div className={'rounded p-1 text-sm border border-black text-black'}>
                    {t('notification_status_created')}
                </div>
            );
        case 'PENDING':
            return (
                <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{t('notification_status_pending')}</div>
            );
        case 'SENDING':
            return (
                <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{t('notification_status_sending')}</div>
            );
        case 'SENT':
            return <div className={'rounded p-1 text-sm bg-green-500 text-white'}>{t('notification_status_sent')}</div>;
        case 'BOUNCED':
            return (
                <div className={'rounded p-1 text-sm bg-warning text-white'}>{t('notification_status_bounced')}</div>
            );
        case 'COMPLAINED':
            return (
                <div className={'rounded p-1 text-sm bg-warning text-white'}>{t('notification_status_complained')}</div>
            );
        case 'DELIVERED':
            return (
                <div className={'rounded p-1 text-sm bg-green-500 text-white'}>
                    {t('notification_status_delivered')}
                </div>
            );
        case 'SEND_FAILED':
            return <div className={'rounded p-1 text-sm bg-red-500 text-white'}>{t('notification_status_failed')}</div>;
        default:
            return <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{value.toLowerCase()}</div>;
    }
}

export type NotificationStatusCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default NotificationStatusCell;
