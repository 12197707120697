import { ReactElement } from 'react';
import { BaseCell } from '../types';
import { useTranslation } from 'react-i18next';

export function BookAttributionStatusCell({ value }: BookAttributionStatusCellProps): ReactElement {
    const { t } = useTranslation();

    switch (value) {
        case 'NON_ATTRIBUTED':
            return (
                <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>
                    {t('book_attribution_status_non_attributed')}
                </div>
            );
        case 'ATTRIBUTED':
            return (
                <div className={'rounded p-1 text-sm bg-green-500 text-white'}>
                    {t('book_attribution_status_attributed')}
                </div>
            );
        default:
            return (
                <div className={'rounded p-1 text-sm text-gray-500'}>{value !== null ? value.toLowerCase() : ''}</div>
            );
    }
}

export type BookAttributionStatusCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default BookAttributionStatusCell;
