import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function GroupingCell({ row: { grouping, groupingCode, groupingName } }: GroupingCellProps): ReactElement {
    const history = useHistory();
    const groupingUri = `/groupings/${grouping}`;

    const onGroupingClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(groupingUri);
        },
        [history, groupingUri],
    );

    return (
        <>
            <a href={groupingUri} onClick={onGroupingClick} className={'hover:underline'}>
                {groupingName ? (
                    <>
                        <b>{groupingName}</b> ({groupingCode})
                    </>
                ) : (
                    <b>{groupingCode}</b>
                )}
            </a>
        </>
    );
}

export type GroupingCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default GroupingCell;
