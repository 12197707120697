import { BaseCell } from '../types';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

export function OfferTypeCell({ value }: OfferTypeCellProps): ReactElement {
    const { t } = useTranslation();

    switch (value) {
        case 'game-registration':
            return (
                <div className={'rounded p-1 text-sm bg-info text-white'}>{t('tenant_offer_game_registration')}</div>
            );
        default:
            return <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{value.toLowerCase()}</div>;
    }
}

export type OfferTypeCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default OfferTypeCell;
