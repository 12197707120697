import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function GodfatherCell({
    row: { godfather, godfatherCode, godfatherEmail, godfatherPhone },
}: GodfatherCellProps): ReactElement {
    const history = useHistory();
    const godfatherUri = `/godfathers/${godfather}`;

    const onGodfatherClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(godfatherUri);
        },
        [history, godfatherUri],
    );

    if (!godfather) return <>{''}</>;

    return (
        <>
            {!!godfather ? (
                <a href={godfatherUri} onClick={onGodfatherClick} className={'hover:underline'}>
                    <b>{godfatherCode}</b> - {godfatherEmail || godfatherPhone || '-'}
                </a>
            ) : (
                <>{''}</>
            )}
        </>
    );
}

export type GodfatherCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default GodfatherCell;
