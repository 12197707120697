import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseCell } from '../types';

export function GameCodeCell({ row: { game, gameCode } }: GameCodeCellProps): ReactElement {
    const history = useHistory();
    const gameUri = `/games/${game}`;

    const onGameClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(gameUri);
        },
        [history, gameUri],
    );

    return (
        <a href={gameUri} onClick={onGameClick} className={'hover:underline'}>
            <b>{gameCode}</b>
        </a>
    );
}

export type GameCodeCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default GameCodeCell;
