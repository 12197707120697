import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function GameNameSelfCell({
    row: { id, name, organization, organizationName },
}: GameNameSelfCellProps): ReactElement {
    const history = useHistory();
    const gameUri = `/games/${id}`;
    const organizationUri = `/organizations/${organization}`;

    const onGameClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(gameUri);
        },
        [history, gameUri],
    );

    const onOrganizationClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(organizationUri);
        },
        [history, organizationUri],
    );

    return (
        <>
            <a href={gameUri} onClick={onGameClick} className={'hover:underline'}>
                {name}
            </a>{' '}
            &nbsp; (
            <a href={organizationUri} onClick={onOrganizationClick} className={'hover:underline'}>
                {organizationName}
            </a>
            )
        </>
    );
}

export type GameNameSelfCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default GameNameSelfCell;
