import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function OfferCell({ value, row: { offerName } }: OfferCellProps): ReactElement {
    const history = useHistory();
    const offerUri = `/offers/${value}`;

    const onOfferClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            history.push(offerUri);
        },
        [history, offerUri],
    );

    return value ? (
        <>
            <a href={offerUri} onClick={onOfferClick} className={'hover:underline'}>
                <b>{offerName}</b>{' '}
            </a>
        </>
    ) : (
        <>{''}</>
    );
}

export type OfferCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default OfferCell;
