import { BaseCell } from '../types';
import { ReactElement } from 'react';

export function NotificationTypeCell({ value }: NotificationTypeCellProps): ReactElement {
    switch (value) {
        case 'email':
            return <div className={'rounded p-1 text-sm bg-info text-white'}>{value.toUpperCase()}</div>;
        case 'sms':
            return <div className={'rounded p-1 text-sm bg-info text-white'}>{value.toUpperCase()}</div>;
        case 'push':
            return <div className={'rounded p-1 text-sm bg-info text-white'}>{value.toUpperCase()}</div>;
        default:
            return <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{value.toLowerCase()}</div>;
    }
}

export type NotificationTypeCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default NotificationTypeCell;
