import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function PlanCell({
    row: { plan, planCode, planName },
}: PlanCellProps): ReactElement {
    const history = useHistory();
    const planUri = `/plans/${plan}`;

    const onPlanClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(planUri);
        },
        [history, planUri],
    );

    return (
        <>
            <a href={planUri} onClick={onPlanClick} className={'hover:underline'}>
                <b>{planCode}</b> - {planName}
            </a>
        </>
    );
}

export type PlanCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default PlanCell;
