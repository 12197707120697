import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { BaseCell } from '../types';
import { useHistory } from 'react-router-dom';

export function SaleCell({ row: { sale } }: SaleCellProps): ReactElement {
    const history = useHistory();
    const saleUri = `/sales/${sale}`;

    const onSaleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(saleUri);
        },
        [history, saleUri],
    );

    if (!sale) return <>{''}</>;

    return (
        <a href={saleUri} onClick={onSaleClick} className={'hover:underline'}>
            <b>{sale}</b>
        </a>
    );
}

export type SaleCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default SaleCell;
