import { useTranslation } from 'react-i18next';
import { BaseCell } from '../types';
import { ReactElement } from 'react';

export function GameStatusCell({ value }: GameStatusCellProps): ReactElement {
    const { t } = useTranslation();

    switch (value) {
        case 'RUNNING':
            return <div className={'rounded p-1 text-sm bg-green-500 text-white'}>{t('game_status_running')}</div>;
        case 'CREATED':
            return (
                <div className={'rounded p-1 text-sm border border-black text-black'}>{t('game_status_created')}</div>
            );
        case 'PAUSED':
            return <div className={'rounded p-1 text-sm bg-red-500 text-white'}>{t('game_status_paused')}</div>;
        case 'DATE_REACHED':
            return <div className={'rounded p-1 text-sm bg-red-500 text-white'}>{t('game_status_date_reached')}</div>;
        case 'CLOSED':
            return <div className={'rounded p-1 text-sm bg-blue-500 text-white'}>{t('game_status_closed')}</div>;
        case 'DRAW_REQUESTED':
            return <div className={'rounded p-1 text-sm bg-warning text-white'}>{t('game_status_draw_requested')}</div>;
        case 'DRAWN':
            return <div className={'rounded p-1 text-sm bg-yellow-500 text-black'}>{t('game_status_drawn')}</div>;
        case 'FINISHED':
            return <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{t('game_status_finished')}</div>;
        default:
            return <div className={'rounded p-1 text-sm bg-gray-500 text-white'}>{value.toLowerCase()}</div>;
    }
}
export type GameStatusCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default GameStatusCell;
